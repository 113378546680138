import logo from './logo.svg';
import './App.css';
import FloorPlanner from './FloorPlanner';

function App() {
  return (
    <div className="App">
      <h1>Floor planner</h1>
      <FloorPlanner></FloorPlanner>
    </div>
  );
}

export default App;
