import React, { useEffect } from 'react'

var ReactDOM = require('react-dom');

export default function FloorPlanner() {

    //sample settings
    // initFPEditor({
    //     // Setting 'apiDomain' to 'sandbox.floorplanner.com' makes the editor
    //     // use our sandbox back-end instead of production. The default value
    //     // is 'floorplanner.com'.
    //     apiDomain: 'sandbox.floorplanner.com',
    
    //     // When developing an embedded Floorplanner Editor locally, you will want
    //     // to set 'embedPrefix' so that editor files are not served through localhost.
    //     // This is only needed if you develop using localhost:3000.
    //     embedPrefix: 'https://fp-editor-cdn.floorplanner.com',
    
    //     // A valid 'document.querySelector' value. The selector specified here
    //     // must exist on the page before 'initFPEditor' is called. 
    //     mountSelector: '#fp-editor-container',
      
    //     // 'useMetric' is a boolean setting which toggles between metric and
    //     // imperial measurement systems. This setting overwrites the user's
    //     // measurements setting. If a template is set, the measurements setting
    //     // from the template will be applied instead.
    //     useMetric: true,
    
    //     // A template offers a way to add consistent branding to floorplan
    //     // exports/renders It is a collection of style and export settings
    //     // which can be managed via the Floorplanner Dashboard.
    //     templateId: 160,
    
    //     // To use the branding styles and settings you will need to the set the
    //     // 'brandingId' property. This can be a numeric ID or the subdomain name of
    //     // the branding. If it is a subdomain, be sure to quote the value.
    //     brandingId: 1,
      
    //     // Type of editor with the kind property. These are the optional values:
    //     // 'spaceplanner', 'editor', 'viewer' and 'roomplanner'
    //     kind: 'editor',
    
    //     // User info, optional for public projects with kind: 'viewer' or 'roomplanner'
    //     user: {
    //         // Floorplanner user id
    //         id: 1337,
    
    //         // Optional, this will set the default email address for the 2D & 3D exports
    //         email: 'support@floorplanner.com',
    
    //         // An array of permissions:
    //         //    'save' shows the save button in the top bar
    //         //    'no-export' hides the 2D & 3D export buttons in the top bar
    //         //    'hide-email' hides the email field at the bottom of the export popup
    //         //    'no-back-to-dashboard' hides the Back to dashboard button in the sidebar
    //         //    'ntl' turns off the cooldown period for renders
    //         permissions: ['save', 'no-export'],
    
    //         // Authentication token to log in as a certain user
    //         auth_token: 'eyJ0eXAiOiJKV1QiLQL1bGciOiJIUzI1NiJ9.eyJhY3Rpb24abaJsb2dpbiIsIm1vZGVsIjoiVXNlciIsImlkIjoxMDMsImlhdCI6MTUyMTczMTIwMH0.juutj2LGAVDUAV103Cz_viPvIfcPcs6weIPl3eFtsdM'
    //     }
    // }).then(api => { /* additional setup using editor API object */ });

    useEffect(() => {
        return () => {
            ReactDOM.unmountComponentAtNode(document.getElementById('fp-editor-container'));
        }
    })

    useEffect(() => {
        const obj = {
            projectId: 111854598,
            mountSelector: '#fp-editor-container',
            user: {
                id: 68472150,
                auth_token: "eyJhbGciOiJFUzI1NiJ9.eyJhY3Rpb24iOiJsb2dpbiIsIm1vZGVsIjoiVXNlciIsImlkIjo2ODQ3MjE1MCwiaWF0IjoxNjM3MDE1NjY4fQ.zzoNkaUa6wmofhTvHFxVlPwxqiE-x4O3z3e2BIK7XC9x8dRnV5jK7aZ0INEdKbqwF-WA8axtqk302vfumdxGVw",
                permissions: ['save'],
            },
            language: 'en-US',
        }

        window.initFPEditor(obj).then(api => {
            var products = api.state.items;
            console.log('api', api);
            window.api = api;
            //api.dropItem('5001d76ba043399d80b1f65fdee901065c0de0d6', {x: 650, y: 533});
            api.onUpdated  = (data) => {
                
                console.log('floor plan updated:', data);
                // onUpdate - Log unique products with count
                // log unique products with counts

                // api.onUpdated = (data) => {
                //     // Get all products from state and map the asset data
                //     var allProducts = data.state.items.map((item) => item.asset);

                //     // Get unique products with counts
                //     var uniqueProducts = [];
                //     for (i = 0; i < allProducts.length; i++) {
                //         var index = uniqueProducts.findIndex(
                //             (product) => product.id === allProducts[i].id
                //         );
                //         if (index >= 0) {
                //             uniqueProducts[index].count++;
                //         } else {
                //             uniqueProducts.push({...allProducts[i], count: 1});
                //         }
                //     }

                //     console.log('unique products', uniqueProducts);
                // };
                // onUpdate - Log products added and removed
                // log products added and removed

                // var products = api.state.items;

                // api.onUpdated = (data) => {
                //     // Get changed item
                //     if (products.length < data.state.items.length) {
                //         console.log('product added', data.state.items[data.state.items.length - 1]);
                //     } else if (products.length > data.state.items.length) {
                //         var allProducts = products.map((product) => product.asset.id);
                //         var currentProducts = data.state.items.map((product) => product.asset.id);
                //         var removedProductIds = allProducts.filter((d) => !currentProducts.includes(d));
                //         var removedProducts = products.filter(
                //             (product) => removedProductIds.includes(product.asset.id)
                //         );

                //         console.log('product(s) removed', removedProducts);
                //     }

                //     // Update products array
                //     products = data.state.items;
                //};
            }
        });
    }, [])
    return (
        <div>
            <div id="fp-editor-container" style={{position: "absolute", height: "800px", width: "calc(100% - 100px)"}}></div>                       
        </div>
    )
}
